import {
  Input,
  Output,
  OnInit,
  ViewChild,
  Component,
  EventEmitter,
  ComponentRef,
  ViewContainerRef
} from '@angular/core'
import { FormGroup, FormsModule } from '@angular/forms'
import { CommonModule } from '@angular/common'
import {
  ReplaySubject,
  debounceTime,
  Observable,
  takeUntil,
  Subject,
  take,
  of
} from 'rxjs'
import _ from 'lodash'
import { TranslocoModule, TranslocoService } from '@jsverse/transloco'

import { ConfirmationService, MessageService } from 'primeng/api'
import { InputTextModule } from 'primeng/inputtext'
import { TableLazyLoadEvent } from 'primeng/table'
import { SkeletonModule } from 'primeng/skeleton'
import { DialogModule } from 'primeng/dialog'
import { ButtonModule } from 'primeng/button'
import { PanelModule } from 'primeng/panel'

import { IAction } from '@satinel-system/grid'
import { Field } from '@satinel-system/core'
import {
  TableField,
  FilterParam,
  FilterField,
  QueryParams,
  FieldConfig,
  ErrorService,
  ColumnConfig,
  BuilderFields,
  BackendService,
  ResponsiveCols,
  EntityInterfaces,
  GridAdapterService,
  tableDefaultConfig
} from '@goplanner/api-client'
import { GridComponent, goplannerGridModule } from '@goplanner/grid-builder'
import { FormDialogModule, FormDialogComponent } from '@goplanner/form-builder'

import {
  columnConfigDireccionesEntrega,
  fieldConfigDireccionesEntrega
} from 'src/app/pages/home/modules/addresses/addresses-fields'
import { timeField } from '@goplanner/module-builder'

const primeng = [
  PanelModule,
  DialogModule,
  ButtonModule,
  SkeletonModule,
  InputTextModule
]

type Entity<T extends keyof EntityInterfaces> = {
  label: string
  icon: string
  entity: T
  fieldConfig?: FieldConfig<T>
  columns?: TableField<T>[]
  columnConfig?: ColumnConfig<T>
  queryFields?: FilterField<T>[]
  cols?: ResponsiveCols
  actions?: IAction<EntityInterfaces[T]['get']>[]
  parentKey:
    | keyof EntityInterfaces[T]['post']
    | keyof EntityInterfaces[T]['put']
}

@Component({
  selector: 'app-dialog-address-details',
  templateUrl: './dialog-address-details.component.html',
  styleUrls: ['./dialog-address-details.component.scss'],
  imports: [
    FormsModule,
    CommonModule,
    TranslocoModule,
    FormDialogModule,
    goplannerGridModule,
    ...primeng
  ],
  standalone: true
})
export class DialogAddressDetailsComponent<
  T extends 'ent_direcciones' | 'cli_direccion_entrega',
  TForm extends keyof EntityInterfaces
> implements OnInit
{
  @ViewChild('grid') grid!: GridComponent<T>

  formDialog: ComponentRef<FormDialogComponent<TForm>> | undefined = undefined

  visible = false

  guardando = false

  errorDialog: { visible: boolean; header: string; message: string } = {
    visible: false,
    header: '',
    message: ''
  }

  @Input() entities: Entity<TForm>[] = []

  // TRANSLATIONS KEYS
  @Input()
  addressDetailsTranslationKey = 'goplanner.ADDRESS_SEARCH.ADDRESS_DETAILS'

  @Input()
  aliasTranslationKey = 'goplanner.COMMON.ALIAS'

  @Input()
  addressTranslationKey = 'goplanner.COMMON.ADDRESS'

  @Input()
  searchPlaceholderTranslationKey = 'goplanner.COMMON.SEARCH_PLACEHOLDER'

  entityName!: T

  entityForm!: TForm

  parentKey!: keyof EntityInterfaces[T]['post']

  data$!: Observable<EntityInterfaces[TForm]['get'][]>

  total$!: Observable<number>

  loading$!: Observable<boolean>

  recordAddress!: EntityInterfaces[T]['get']

  recordAsociatedAddress!: any

  coordenadasString!: string

  showForm = false

  @Input() tableModel: BuilderFields[] = []
  @Input() formModel: BuilderFields[] = []

  @Output()
  destroy = new EventEmitter<void>()

  @Output()
  saved = new EventEmitter<EntityInterfaces[T]['get']>()

  formConfigAsociateAddress = {}

  /**
   * Destroy subject
   */
  private destroy$: ReplaySubject<boolean> = new ReplaySubject(1)

  formToAsociate = new FormGroup({})

  gridActions: IAction<EntityInterfaces[TForm]['get']>[] = [
    {
      icon: 'pi pi-pencil',
      color: 'p-button-success',
      handler: (record) => this.openForm('update', record)
    },
    {
      icon: 'pi pi-trash',
      color: 'p-button-danger',
      handler: (record) => this.delete(record)
    }
  ]

  actions: IAction<EntityInterfaces[TForm]['get']>[] = []

  constructor(
    private backendService: BackendService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private errorService: ErrorService,
    private vcr: ViewContainerRef,
    private gridAdapter: GridAdapterService,
    private translate: TranslocoService
  ) {}

  ngOnInit(): void {
    const record: any = this.recordAddress
    const coordenadas: any =
      'coordenadas' in record && record.coordenadas
        ? record.coordenadas
        : 'direccion' in record &&
            record.direccion &&
            typeof record.direccion === 'object' &&
            'coordenadas' in record.direccion &&
            record.direccion.coordenadas
          ? record.direccion.coordenadas
          : undefined

    if (
      coordenadas &&
      'type' in coordenadas &&
      coordenadas.type === 'Point' &&
      'coordinates' in coordenadas &&
      Array.isArray(coordenadas.coordinates) &&
      coordenadas.coordinates.length === 2
    )
      this.coordenadasString = `${coordenadas.coordinates[1]},${coordenadas.coordinates[0]}`

    this.getEntities()

    const entity = this.entities[0]

    this.entityForm = entity.entity as TForm
    this.parentKey = entity.parentKey as keyof EntityInterfaces[TForm]['post']
    this.actions = [...this.gridActions, ...(entity.actions ?? [])]

    this.queryString$
      .pipe(takeUntil(this.destroy$), debounceTime(500))
      .subscribe((query) => {
        this.query(query)
      })

    this.getTableModel(entity)
  }

  getEntities() {
    if (this.entityName === 'ent_direcciones') {
      this.entities = [
        {
          entity: 'cli_direccion_entrega' as TForm,
          label: 'Clientes Habituales',
          icon: 'fas fa-user-tie',
          columns: [
            'cliente__nombre_calculado',
            'sucursal__nombre',
            'tipo_direccion_entrega__nombre',
            'codigo_erp'
          ] as TableField<TForm>[],
          fieldConfig: fieldConfigDireccionesEntrega,
          actions: [
            {
              icon: 'fas fa-external-link-alt',
              color: 'p-button-info',
              handler(item: EntityInterfaces['cli_direccion_entrega']['get']) {
                window.open(
                  `/clientes/${item.id_cliente}/direccion-entrega/${item.id}`,
                  '_blank'
                )
              }
            }
          ] as IAction<EntityInterfaces[TForm]['get']>[],
          cols: {
            md: 6
          },
          queryFields: [
            'cliente__nombre_calculado',
            'sucursal__nombre',
            'tipo_direccion_entrega__nombre',
            'codigo_erp'
          ] as FilterField<TForm>[],
          columnConfig: columnConfigDireccionesEntrega as ColumnConfig<TForm>,
          parentKey: 'id_direccion' as keyof EntityInterfaces[TForm]['post']
        },
        {
          entity: 'ent_direcciones_contactos' as TForm,
          label: 'Contactos',
          icon: 'fas fa-user',
          fieldConfig: {
            email: {
              validators: {
                validation: ['email']
              }
            },
            persona_contacto: {
              cols: {
                md: 12
              }
            }
          } as unknown as FieldConfig<TForm>,
          cols: {
            md: 6
          },
          queryFields: [
            'persona_contacto',
            'email',
            'telefono',
            'tipo_telefono__nombre',
            'observaciones'
          ] as FilterField<TForm>[],
          parentKey: 'id_direccion' as keyof EntityInterfaces[TForm]['post']
        },
        {
          entity: 'ent_direcciones_horarios' as TForm,
          label: 'Horarios',
          icon: 'fas fa-clock',
          fieldConfig: {
            hora_inicio: {
              type: 'dropdown',
              props: timeField
            },
            hora_fin: {
              type: 'dropdown',
              props: timeField
            }
          } as unknown as FieldConfig<TForm>,
          queryFields: ['nombre'] as FilterField<TForm>[],
          cols: {
            md: 6
          },
          parentKey: 'id_direccion' as keyof EntityInterfaces[TForm]['post']
        },
        {
          entity: 'ent_direcciones_zonas' as TForm,
          label: 'Zonas',
          icon: 'fas fa-map-marked-alt',
          queryFields: ['zona__nombre'] as FilterField<TForm>[],
          parentKey: 'id_direccion' as keyof EntityInterfaces[TForm]['post']
        }
      ]
    }
  }

  columnConfig: ColumnConfig<TForm> = {}

  queryFields: FilterField<TForm>[] = []

  gridColumns!: TableField<TForm>[]

  activeField!: Field<EntityInterfaces[TForm]['get'], ''> | null

  getTableModel(entity: Entity<TForm>) {
    const defaultConfig = tableDefaultConfig[this.entityForm]
    this.activeField = defaultConfig?.activeField ?? null
    this.columnConfig =
      entity.columnConfig ?? defaultConfig?.columnsConfig ?? {}
    this.queryFields = entity.queryFields ?? defaultConfig?.queryFields ?? []
    this.gridColumns = entity.columns ?? defaultConfig?.searchColumns ?? []
    this.backendService
      .getOrLoad('builder', {
        filter: [
          {
            field: 'table_name_parent',
            operator: '=',
            value: this.entityForm
          }
        ]
      })
      .pipe(take(1))
      .subscribe((tableModel) => {
        this.tableModel = tableModel
        this.data$ = this.backendService.get(this.entityForm, {
          filter: [
            {
              field: this.parentKey,
              operator: '=',
              value: this.recordAddress.id
            } as FilterParam<TForm>
          ],
          limit: 10,
          offset: 0
        })
        this.total$ = this.backendService.total$(this.entityForm)
        this.loading$ = this.backendService.loading$(this.entityForm)
      })
  }

  activeSection(form: TForm) {
    const entity = this.entities.find((e) => e.entity === form)
    if (!entity) return
    this.entityForm = form
    this.parentKey = entity.parentKey as keyof EntityInterfaces[TForm]['post']
    this.actions = [...this.gridActions, ...(entity.actions ?? [])]
    this.loading$ = of(true)
    this.getTableModel(entity)
  }

  openForm(mode: 'create' | 'update', record?: EntityInterfaces[TForm]['get']) {
    if (this.formDialog) return
    this.backendService
      .getOrLoad('builder', {
        filter: [
          {
            field: 'table_name_parent',
            operator: '=',
            value: this.entityForm
          }
        ]
      })
      .pipe(take(1))
      .subscribe((formModel) => {
        this.formDialog = this.vcr.createComponent(FormDialogComponent<TForm>)
        const entity = this.entities.find((e) => e.entity === this.entityForm)
        this.formDialog.instance.entityName = this.entityForm
        this.formDialog.instance.formType = mode
        this.formDialog.instance.tableModel = formModel
        this.formDialog.instance.fieldConfig = entity?.fieldConfig ?? {}
        this.formDialog.instance.record = record
          ? record
          : ({
              [this.parentKey]: this.recordAddress.id
            } as unknown as EntityInterfaces[TForm]['get'])
        this.formDialog.instance.excludeFields = [
          this.parentKey as keyof EntityInterfaces[TForm]['get']
        ]
        this.formDialog.instance.cols = entity?.cols ?? {
          md: 12
        }
        this.formDialog.instance.saveButtonIcon = 'pi pi-save'
        this.formDialog.instance.saveAndSelectButtonTranslationKey =
          'goplanner.COMMON.SAVE'
        this.formDialog.instance.showSaveButton = false
        this.formDialog.instance.formVisible = true

        const afterSave = this.formDialog.instance.afterSave
          .pipe(takeUntil(this.destroy$))
          .subscribe((record) => {
            this.backendService
              .getCached(this.entityForm, this.queryParams)
              .pipe(take(1))
              .subscribe()
          })

        const destroy = this.formDialog.instance.destroy
          .pipe(takeUntil(this.destroy$), debounceTime(200))
          .subscribe(() => {
            afterSave?.unsubscribe()
            destroy?.unsubscribe()
            this.formDialog?.destroy()
            this.formDialog = undefined
          })
      })
  }

  openGoogleMaps(latlng?: string) {
    if (latlng) {
      const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${latlng}`
      window.open(googleMapsUrl, '_blank')
    }
  }

  getAlias(): string {
    return 'alias_direccion' in this.recordAddress
      ? (this.recordAddress.alias_direccion as string) || '---'
      : '---'
  }

  getDireccion(): string {
    return 'direccion_formateada' in this.recordAddress
      ? (this.recordAddress.direccion_formateada as string) || '---'
      : '---'
  }

  /**
   * The query string
   */
  queryString = ''

  /**
   * The query filters
   */
  queryFilters: (FilterParam<TForm> | 'OR' | 'AND')[] = []

  /**
   * The query string subject
   */
  private queryString$ = new Subject<string>()

  /**
   * The query params
   */
  queryParams: QueryParams<TForm> = {}

  /**
   * On lazy load, change the query
   * @param event the lazy load event
   */
  lazyLoad(event: TableLazyLoadEvent) {
    if (!this.tableModel.length) return
    this.changeQuery({
      ...this.gridAdapter.parseGridLazyLoadEvent(this.entityForm, event),
      filter: this.queryFilters
    })
  }

  /**
   * On search, emit to the query string subject
   * @param query the query string
   */
  search(query: string) {
    this.queryString$.next(query)
  }

  /**
   * On query, change the query and reset the page
   * @param query the query string
   */
  query(query: string): void {
    this.queryString = query
    this.queryFilters =
      this.queryString && this.queryString !== '' && this.queryFields.length > 0
        ? this.gridAdapter.parseQueryFilters(
            this.entityForm,
            this.queryString,
            this.queryFields
          )
        : []

    if (this.queryParams.offset) {
      delete this.queryParams.offset
      this.grid.grid.dataTable.first = 0
    }

    this.changeQuery({
      ...this.queryParams,
      filter: this.queryFilters ?? []
    })
  }

  /**
   * On reset, reset the sort and page
   */
  resetSort() {
    this.grid.resetSort()
    if (this.queryParams.offset) delete this.queryParams.offset
    this.changeQuery({
      ...this.queryParams,
      sort: []
    })
  }

  /**
   * Function to change the query
   * @param query the query params
   */
  changeQuery(query: QueryParams<TForm>) {
    // If the query is the same, do nothing
    if (_.isEqual(query, this.queryParams)) return
    // If the filter or sort changed, clear the cache
    if (
      !_.isEqual(query.filter, this.queryParams.filter) ||
      !_.isEqual(query.sort, this.queryParams.sort)
    ) {
      this.backendService.getEntityCollection(this.entityName).clearCache()
    }
    this.queryParams = {
      ...query,
      filter: [
        ...(query.filter ?? []),
        {
          field: this.parentKey,
          operator: '=',
          value: this.recordAddress.id
        } as FilterParam<TForm>
      ],
      limit: 10
    }
    this.backendService
      .getOrLoad(this.entityForm, this.queryParams)
      .pipe(take(1))
      .subscribe()
  }

  @Input()
  deleteRegistryTitleTranslationKey = 'goplanner.COMMON.DELETE_REGISTRY_TITLE'

  @Input()
  deleteRegistryMessageTranslationKey =
    'goplanner.COMMON.DELETE_REGISTRY_MESSAGE'

  @Input()
  deleteRegistryAcceptTranslationKey = 'goplanner.COMMON.DELETE_REGISTRY_ACCEPT'

  @Input()
  deleteRegistryRejectTranslationKey = 'goplanner.COMMON.DELETE_REGISTRY_REJECT'

  @Input()
  deleteRegistrySuccessTranslationKey = 'goplanner.COMMON.SUCCESS'

  @Input()
  deleteRegistrySuccessMessageTranslationKey =
    'goplanner.COMMON.DELETE_REGISTRY_SUCCESS'

  /**
   * Delete a record
   * @param record the record to delete
   */
  delete(record: EntityInterfaces[TForm]['get']): void {
    this.confirmationService.confirm({
      header: this.translate.translate(this.deleteRegistryTitleTranslationKey),
      message: this.translate.translate(
        this.deleteRegistryMessageTranslationKey
      ),
      acceptLabel: this.translate.translate(
        this.deleteRegistryAcceptTranslationKey
      ),
      rejectLabel: this.translate.translate(
        this.deleteRegistryRejectTranslationKey
      ),
      acceptButtonStyleClass: 'p-button-danger',
      rejectButtonStyleClass: 'p-button-secondary p-button-text',
      acceptIcon: 'pi pi-trash mr-2',
      rejectIcon: ' ',
      accept: () => {
        this.backendService
          .delete(this.entityForm, Number(record['id']))
          .pipe(take(1))
          .subscribe({
            next: () => {
              this.messageService.add({
                severity: 'success',
                summary: this.translate.translate(
                  this.deleteRegistrySuccessTranslationKey
                ),
                detail: this.translate.translate(
                  this.deleteRegistrySuccessMessageTranslationKey
                )
              })
            },
            error: (error) => {
              this.errorService.showErrorDialog(
                this.entityForm,
                this.tableModel,
                error,
                'delete'
              )
            },
            complete: () => {
              this.backendService
                .getCached(this.entityForm, this.queryParams)
                .pipe(take(1))
                .subscribe((entities) => {
                  if (entities.length === 0) {
                    this.backendService
                      .getEntityCollection(this.entityForm)
                      .updateGetResponse(entities)
                  }
                })
            }
          })
      }
    })
  }

  /**
   * On destroy hide the dialog and emit the destroy event
   */
  onDestroy() {
    this.destroy$.next(true)
    this.destroy$.complete()
    this.destroy.emit()
  }
}
