<p-dialog
  [(visible)]="visible"
  appendTo="body"
  styleClass="overflow-hidden"
  [modal]="true"
  [dismissableMask]="false"
  [closeOnEscape]="false"
  [draggable]="false"
  [resizable]="false"
  [focusTrap]="true"
  contentStyleClass="pb-0"
  [style]="{ width: '80rem', 'max-width': '90dww' }"
  (onHide)="onDestroy()"
>
  <p-header>
    <div class="flex gap-2 align-items-center justify-content-center">
      <div
        class="flex align-items-center justify-content-center surface-100 text-cyan-800 border-circle"
        style="width: 2.2rem; height: 2.2rem"
      >
        <i class="fa-solid fa-list text-lg"></i>
      </div>
      <div class="font-medium text-xl text-900">
        {{ addressDetailsTranslationKey | transloco }}
      </div>
    </div>
  </p-header>
  <div class="py-3 border-top-1 border-300">
    <div class="grid">
      <div class="col-12 md:col-6">
        <ul class="list-none p-0 m-0 mb-2">
          <li>
            {{ aliasTranslationKey | transloco }}:
            <span class="font-bold">{{ getAlias() }}</span>
          </li>
          <li>
            {{ addressTranslationKey | transloco }}:
            <span class="font-bold">{{ getDireccion() }}</span>
          </li>
        </ul>
      </div>
      <div class="col-12 md:col-6">
        <div
          class="surface-100 p-1 border-round text-xl text-center flex align-items-center"
        >
          <div class="flex-1 select-all">
            {{ coordenadasString }}
          </div>
          <div>
            <p-button
              icon="fas fa-arrow-up-right-from-square"
              [text]="true"
              severity="secondary"
              (onClick)="openGoogleMaps(coordenadasString)"
            ></p-button>
          </div>
        </div>
      </div>
    </div>

    <div class="flex gap-2 my-3">
      @if (entities.length > 1) {
        <div
          class="surface-card shadow-2 border-round select-none w-15rem"
          style="height: 36.25rem"
        >
          <ul class="list-none p-0 m-0 mb-2">
            @for (item of entities; track item) {
              <li
                class="boton_list"
                [ngClass]="{
                  'border-left-3 border-blue-400 surface-100':
                    entityForm === item.entity
                }"
                (click)="activeSection(item.entity)"
              >
                <i
                  class="{{ item.icon }} mr-2 text-700 w-2rem text-center mr-2"
                ></i>
                <span class="font-medium text-700">{{ item.label }}</span>
              </li>
            }
          </ul>
        </div>
      }
      <div
        class="flex-1 flex flex-column gap-2 overflow-hidden surface-card shadow-2 border-round p-3"
        style="height: 36.25rem"
      >
        <div class="flex gap-2 align-content-center">
          @if (!!queryParams.sort && queryParams.sort.length > 0) {
            <p-button
              pRipple
              type="button"
              icon="pi pi-sort-alt-slash"
              [rounded]="true"
              [outlined]="true"
              severity="secondary"
              [style]="{
                width: '2rem',
                height: '2rem'
              }"
              styleClass="border-500"
              (onClick)="resetSort()"
            />
          }
          @if (queryFields.length > 0) {
            <section
              class="flex-1 border-1 border-bluegray-200 border-round-lg"
            >
              <div
                class="flex w-full align-items-center justify-content-between px-1"
              >
                <span class="p-input-icon-left w-full">
                  <i class="pi pi-search text-500"></i>
                  <input
                    type="text"
                    pInputText
                    [placeholder]="searchPlaceholderTranslationKey | transloco"
                    [(ngModel)]="queryString"
                    (ngModelChange)="search($event)"
                    class="w-full border-none shadow-none outline-none text-500"
                  />
                </span>
                <span
                  tabindex="0"
                  role="button"
                  (click)="queryString = ''; search('')"
                  class="py-1 px-2 text-600 cursor-pointer"
                  ><i class="fa-regular fa-xmark"></i
                ></span>
              </div>
            </section>
          }
          <p-button
            icon="fas fa-plus"
            [outlined]="true"
            [label]="'goplanner.COMMON.ADD_NEW' | transloco"
            (onClick)="openForm('create')"
          ></p-button>
        </div>
        <goplanner-grid
          #grid
          class="flex-1 h-full"
          [table]="entityForm"
          [data]="(data$ | async) || []"
          [total]="(total$ | async) || 0"
          [loading]="(loading$ | async) || false"
          [actions]="actions"
          [tableModel]="tableModel"
          [activeField]="activeField"
          [columns]="gridColumns"
          [columnsConfig]="columnConfig"
          size="sm"
          [rows]="10"
          [lazy]="true"
          [pageLinks]="3"
          [showPaginator]="true"
          [rowsPerPageOptions]="undefined"
          (lazyLoad)="lazyLoad($event)"
          (dblClick)="openForm('update', $event)"
        />
      </div>
    </div>
  </div>
</p-dialog>
