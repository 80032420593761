<p-dialog
  [(visible)]="visible"
  appendTo="body"
  styleClass="overflow-hidden "
  [modal]="true"
  [dismissableMask]="false"
  [closeOnEscape]="false"
  [draggable]="false"
  [resizable]="false"
  [focusTrap]="true"
  contentStyleClass="pb-0"
  [style]="{ width: '50rem', 'max-width': '90ww' }"
  (onHide)="onDestroy()"
>
  <p-header>
    <div class="flex gap-2 align-items-center justify-content-center">
      <div
        class="flex align-items-center justify-content-center surface-100 text-cyan-800 border-circle"
        style="width: 2.2rem; height: 2.2rem"
      >
        <i class="fa-solid fa-map-marker text-lg"></i>
      </div>
      <div class="font-medium text-xl text-900">
        {{
          (formType === 'update'
            ? editAddressTranslationKey
            : addAddressTranslationKey
          ) | transloco
        }}
      </div>
    </div>
  </p-header>
  <div class="py-2 border-top-1 border-300">
    @if (tableModel.length === 0) {
      <div class="flex flex-column gap-3">
        <p-skeleton width="100%" height="5rem"></p-skeleton>
        <p-skeleton width="100%" height="5rem"></p-skeleton>
        <p-skeleton width="100%" height="5rem"></p-skeleton>
      </div>
    }

    <goplanner-form
      #form
      table="ent_direcciones"
      [tableModel]="tableModel"
      [record]="recordAddress"
      [fields]="includedAddressFields"
      [fieldConfig]="fieldAddressConfig"
      [cols]="{
        md: 6
      }"
    />

    <div
      class="surface-100 mt-2 p-1 border-round text-xl text-center flex align-items-center"
    >
      <div class="flex-1 select-all flex flex-column gap-1">
        {{ coordenadasString }}
        @if (recordAddress) {
          <span class="text-sm text-500">
            {{ recordAddress.direccion_formateada }}
          </span>
        }
      </div>
      <div>
        <p-button
          icon="fas fa-arrow-up-right-from-square"
          [text]="true"
          severity="secondary"
          (onClick)="openGoogleMaps(coordenadasString)"
        ></p-button>
      </div>
    </div>
  </div>

  <ng-template pTemplate="footer">
    <div class="border-top-1 surface-border pt-3">
      <button
        pButton
        pRipple
        icon="pi pi-times"
        (click)="visible = false"
        [label]="cancelButtonTranslationKey | transloco"
        class="p-button-secondary p-button-text"
      ></button>

      @if (formType === 'update') {
        <p-button
          icon="fas fa-list"
          severity="info"
          [outlined]="true"
          [label]="editAddressDetailsTranslationKey | transloco"
          (onClick)="editAddressDetails(recordAddress)"
        ></p-button>
      }

      <span class="p-buttonset">
        <button
          pButton
          pRipple
          [loading]="loading"
          icon="pi pi-save"
          [label]="saveButtonTranslationKey | transloco"
          (click)="saveAddress(true)"
          class="mr-0"
        ></button>
        <button
          pButton
          pRipple
          [loading]="loading"
          icon="pi pi-save"
          [label]="saveAndCloseButtonTranslationKey | transloco"
          (click)="saveAddress()"
          class="bg-indigo-600"
        ></button>
      </span>
    </div>
  </ng-template>
</p-dialog>
